@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.categoryNav {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between ;
  position: relative;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  color: black;
  /*box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);*/
  flex-wrap: wrap;
  
}


.url {
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
    margin-left: 1rem;
    justify-content: flex-start;
  }

.categoryNav ul {
    display: flex;
    padding: 0;
  }
.categoryNav li {
    list-style-type: none;
    margin: 0 1rem;
  }
.categoryNav li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }

.files {
    border: 0;
    width: 40px;
    height: 40px;
    padding: 0.5rem;
    border-radius: 300%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    z-index: 5;
    display: flex;
    justify-content: center;
  }
.files:hover {
    background-color: #2642af;
  }

    .categoryNav ul {
      display: none;
    }

  
    .categoryNav.expanded {
      background-color: rgba(255, 255, 255, 0);
      
    }
    .categoryNav ul {
      position: absolute;
      top: 0;
      left: 45%;
      flex-direction: column;
      width: 55%;
      /* height: calc(100vh - 77px); */
      background-color: white;
      border-top: 1px solid black;
    }
    .categoryNav li {
      text-align: center;
      margin: 0;
    }
    .categoryNav li a {
      color: black;
      width: 100%;
      padding: 1.5rem 0;
    }
    .categoryNav li:hover {
      background-color: #eee;
    }
    .categoryNav li:focus-within {
      background-color: rgb(62, 238, 46);
    }

  .categoryNav.expanded ul {
    display: block;
    background-color: rgb(184, 184, 184);
    z-index: 4;
  }

  .categoryNav ul {
      /* previous styles */
      display: none;
    }

  @media screen and (max-width: 600px) {
    .categoryNav ul{
      width: 100%;
      
      left: 0%;
      flex-direction: column;
      width: 100%;
    }
    .categoryNav{
      width: 100%;
    }
    .url {
      font-size: small;
    }
  }