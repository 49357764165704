@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap);
.App {
  background: rgb(255, 0, 228);
  background: radial-gradient(
    circle,
    rgba(255, 0, 228, 1) 0%,
    rgba(212, 51, 16, 1) 84%,
    rgba(255, 0, 95, 1) 100%
  );
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

body {
  margin: 0%;
  padding: 0%;
  font-family: montserrat, arial, verdana;
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.form-container .header {
  background-color: rgb(255, 255, 255);
  flex: 20% 1;
  display: grid;
  place-items: center;
  padding: 10px;
  margin-bottom: 5px;
}

.form-container .body {
  flex: 60% 1;
}

.form-container .footer {
  flex: 20% 1;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  padding-top: 20px;
}

.form-container .footer button {
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: rgb(13, 9, 211);
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
}

.sign-up-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-up-container input {
  margin: 5px;
  width: 200px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}

.personal-info-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: 15%;

}

.personal-info-container input {
  margin: 5px;
  width: 60%;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}

/* PROGRESS BAR */
.progressbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  height: 10px;
  background-color: white;
  margin-bottom: 5px;
  z-index: 3; 
}

.progressbar div {
  width: 33.3%;
  height: 100%;
  background-color: rgb(98, 0, 255);
  
}

.other-info-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.other-info-container input {
  margin: 5px;
  width: 200px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}

input {
  border: 2px solid rgb(98, 0, 255);
  border-radius: 5px;
}

input:focus {
  border: 3px solid rgb(98, 0, 255);
}

.hide {
  display: none;
}

/* CSS */
.button-21 {
  align-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #3EB2FD;
  background-image: linear-gradient(1deg, #4F58FD, #149BF3 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-21:active,
.button-21:focus {
  outline: none;
}

.button-21:hover {
  background-position: -20px -20px;
}

.button-21:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}

@media screen and (max-width: 768px) {
  h1{
    font-size: 1.3em;
    margin: 0 10px;
    text-align: center;
  }
}


.disabled{
    display: none;
}
.app {
    font-family: sans-serif;
    height: 100vh;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  .filter-container {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }
  #category-list {
    padding: 5px;
  }
  .sport-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
  }
  .content{
    max-width: 100%;
    height: 100%;
  }

  .pdf{
    width: 100%;
    height: 800px;
  }
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.categoryNav {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between ;
  position: relative;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  color: black;
  /*box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);*/
  flex-wrap: wrap;
  
}


.url {
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
    margin-left: 1rem;
    justify-content: flex-start;
  }

.categoryNav ul {
    display: flex;
    padding: 0;
  }
.categoryNav li {
    list-style-type: none;
    margin: 0 1rem;
  }
.categoryNav li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }

.files {
    border: 0;
    width: 40px;
    height: 40px;
    padding: 0.5rem;
    border-radius: 300%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    z-index: 5;
    display: flex;
    justify-content: center;
  }
.files:hover {
    background-color: #2642af;
  }

    .categoryNav ul {
      display: none;
    }

  
    .categoryNav.expanded {
      background-color: rgba(255, 255, 255, 0);
      
    }
    .categoryNav ul {
      position: absolute;
      top: 0;
      left: 45%;
      flex-direction: column;
      width: 55%;
      /* height: calc(100vh - 77px); */
      background-color: white;
      border-top: 1px solid black;
    }
    .categoryNav li {
      text-align: center;
      margin: 0;
    }
    .categoryNav li a {
      color: black;
      width: 100%;
      padding: 1.5rem 0;
    }
    .categoryNav li:hover {
      background-color: #eee;
    }
    .categoryNav li:focus-within {
      background-color: rgb(62, 238, 46);
    }

  .categoryNav.expanded ul {
    display: block;
    background-color: rgb(184, 184, 184);
    z-index: 4;
  }

  .categoryNav ul {
      /* previous styles */
      display: none;
    }

  @media screen and (max-width: 600px) {
    .categoryNav ul{
      width: 100%;
      
      left: 0%;
      flex-direction: column;
      width: 100%;
    }
    .categoryNav{
      width: 100%;
    }
    .url {
      font-size: small;
    }
  }
  .intro-logo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }

  .intro-logo h1 {
    font-size: 3rem;
    font-weight: 900;
    text-shadow: 2px 2px #444549;
    font-family: 'Philosopher', sans-serif;
    color: rgb(255, 255, 255);
    margin-top: 100px;
    margin-bottom: 20px;
  }

  .results h1 {
    text-align: center;
    text-shadow: 2px 2px #444549;
    font-size: 2.5rem;
    font-weight: 900;
    font-family: 'Philosopher', sans-serif;
    color: rgb(255, 255, 255);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .intro-logo h3 {
    font-size: 2rem;
    font-weight: 300;
    color: rgb(0, 0, 0);
    margin-bottom: 3em;
  }

  .intro-logo img {
    max-width: 95%;
    height: auto;
  }
  .company-icons
  {
    margin-left: 1rem;
    position: absolute;
    bottom: 1rem;
    left:0;
    right:0;
    
  }

  .download {
    display: flex;
    justify-content: flex-end;
    text-decoration: none;
  }


  .company-icons__item + .company-icons__item {
    margin-left: 1rem;
  }
  
  .company-icons__item i {
    margin-right: 5px;
  }
  .intro-button {
    margin-top: 2.3em;
    margin-bottom: 3em;
    
  }

  .intro-button a {
    box-shadow: 1px 1px #7070728c;
    text-transform: uppercase;
    padding: .5em 1em;
    border-radius: 20px;
    color: rgb(255, 255, 255);
    background: #18bb34;
    transition: all 0.5s;
    font-weight: 500;
    font-size: 1.6em;
    text-decoration: none;

  }
  .intro-button a:hover {
    background-color: #3452fa;
    color: rgb(255, 255, 255);
  }

  .company-icons {
    color: rgb(255, 255, 255);
    font-size: 0.95em;
  }

  .company-icons a {
    color: rgb(255, 255, 255);
    font-size: 0.95em;
    text-decoration: none;
  }
  .company-icons__item {
    transition: all 0.5s;
  }
  .company-icons a:hover {
    color: rgb(188, 242, 255);
    cursor: pointer;
  }

  .summary {
    padding: 20px 50px;
    width: 100%;
    background-color: white;
    justify-content: center;
    margin: auto;
  }

  .title {
    padding: 20px 50px;
    width: 100%;
    background-color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .text {
    padding: 5px 0;
  }

  .risk {
    width: 40%;
    margin: 20px;
    padding: 5px 0;
    background-color: white;
    text-align: center;
  }


  @media (max-width: 560px) {
    .intro-logo h1 {
      font-size: 2rem;
      margin:2rem 0;
    }
    .intro-logo h3 {
      font-size: 1.5rem;
      margin:2rem 0;
    }

    .company-icons__item + .company-icons__item {
      margin-left: .5rem;
    }

    .company-icons {
      font-size: 0.8em;
    }
  
    .intro-button{
      margin: 0;
    }
    .intro-button a {
      padding: .6em;
      font-size: 1em;
    }
    .results{
      width: 100%;

    }
    
    .results h1{
      font-size: 1.5rem;

    }
    
  }

  @media (max-width: 800px) {
    .risk {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  
  }
.container{
    width: 100%;
    margin: 5px 0;
}
.table{
    width: 100%;
    margin: 10px auto;

}

.riskContainer{
    width: 40%;
    margin: 5px 0;
}

.cell{
    background-color: rgba(255, 0, 0, 0.438);
}

.green{
    background-color: rgba(0, 128, 0, 0.733);
}

.red{
    background-color: rgba(255, 0, 0, 0.678);
}

.yellow{
    background-color: rgba(255, 255, 0, 0.623);
}

.gray{
    background-color: rgba(128, 128, 128, 0.658);
}
.TableCellTitle {
    font-size: 5rem;
}

@media (max-width: 560px) {
.TableCellTitle {
    font-size: 4px;
}
.table{
    width: 100%;
}
}

.bg{
    background-color: aliceblue;
    display: flex;
    justify-content: left;
    margin: auto;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  
  padding: 1rem;
}

article h1 {
  margin: 1rem 0;
}

.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  margin-bottom: 5px;
}

.brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
  }
.navigation-menu {
    margin-left: auto;
  }

.navigation-menu ul {
    display: flex;
    padding: 0;
  }
.navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
.navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }

.hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
.hamburger:hover {
    background-color: #2642af;
  }

.displayNone {
  display: none ;
}

  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
  }

  @media screen and (max-width: 769px) {
    .navigation-menu ul {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      position: absolute;
      top: 60px;
      left: 0;
      flex-direction: column;
    width: 100%;
     /* height: calc(100vh - 77px); */
      background-color: white;
      border-top: 1px solid black;
    }
    .navigation-menu li {
      text-align: center;
      margin: 0;
    }
    .navigation-menu li a {
      color: rgb(255, 255, 255);
      width: 100%;
      padding: 1.5rem 0;
    }
    .navigation-menu li:hover {
      background-color: #eee;
    }
    .navigation-menu li a:hover {
      background-color: #eee;
      color: black;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      /* previous styles */
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu.expanded ul {
      display: block;
      background-color: rgba(24, 86, 168, 0.979);
      z-index: 4;
    }
  }
body {
    background-color: rgba(0,183,255, 1);
}
