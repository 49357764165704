@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  
  padding: 1rem;
}

article h1 {
  margin: 1rem 0;
}

.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  margin-bottom: 5px;
}

.brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
  }
.navigation-menu {
    margin-left: auto;
  }

.navigation-menu ul {
    display: flex;
    padding: 0;
  }
.navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
.navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }

.hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
.hamburger:hover {
    background-color: #2642af;
  }

.displayNone {
  display: none ;
}

  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
  }

  @media screen and (max-width: 769px) {
    .navigation-menu ul {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      position: absolute;
      top: 60px;
      left: 0;
      flex-direction: column;
    width: 100%;
     /* height: calc(100vh - 77px); */
      background-color: white;
      border-top: 1px solid black;
    }
    .navigation-menu li {
      text-align: center;
      margin: 0;
    }
    .navigation-menu li a {
      color: rgb(255, 255, 255);
      width: 100%;
      padding: 1.5rem 0;
    }
    .navigation-menu li:hover {
      background-color: #eee;
    }
    .navigation-menu li a:hover {
      background-color: #eee;
      color: black;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      /* previous styles */
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu.expanded ul {
      display: block;
      background-color: rgba(24, 86, 168, 0.979);
      z-index: 4;
    }
  }